var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-menu menu-fixed menu-accordion menu-shadow",class:[
  {
    expanded:
      !_vm.isVerticalMenuCollapsed ||
      (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
  },
  _vm.skin === 'semi-dark' ? 'menu-dark' : 'menu-light' ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"click":function($event){return _vm.updateMouseClicked(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"navbar-header expanded",staticStyle:{"margin-bottom":"10px"}},[_vm._t("header",function(){return [_c('ul',{staticClass:"nav navbar-nav flex-row"},[_c('li',{staticClass:"nav-item mr-auto"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":_vm.$store.getters['Users/userData'].user
            ? _vm.$store.getters['Users/userData'].user.role.id === 3
              ? { name: 'home-corporate-account' }
              : { name: 'home-admin' }
            : ''}},[_c('b-img',{attrs:{"src":require("@/assets/images/logo/amera-main.png"),"height":"63"}})],1)],1),_c('li',{staticClass:"nav-item nav-toggle"},[_c('b-link',{staticClass:"nav-link modern-nav-toggle"},[_c('feather-icon',{staticClass:"d-block d-xl-none",attrs:{"icon":"XIcon","size":"20"},on:{"click":_vm.toggleVerticalMenuActive}}),_c('feather-icon',{staticClass:"d-none d-xl-block collapse-toggle-icon",attrs:{"icon":_vm.collapseTogglerIconFeather,"size":"20"},on:{"click":_vm.toggleCollapsed}})],1)],1)])]},{"toggleVerticalMenuActive":_vm.toggleVerticalMenuActive,"toggleCollapsed":_vm.toggleCollapsed,"collapseTogglerIcon":_vm.collapseTogglerIcon})],2),_c('div',{staticClass:"shadow-bottom",class:{ 'd-block': _vm.shallShadowBottom }}),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
      _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
    }}},[(this.$store.getters['Users/userData'].user
      ? this.$store.getters['Users/userData'].user.role === 3
      : ''
    )?_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.getItemsRolCorporateAccount()}}):_vm._e(),(this.$store.getters['Users/userData'].user
      ? this.$store.getters['Users/userData'].user.role !== 3
      : ''
    )?_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.getItemsByPermision()}}):_vm._e(),(this.$store.getters['Users/userData'].user
      ? this.$store.getters['Users/userData'].user.role.id === 3
      : ''
    )?_c('div',{staticStyle:{"position":"absolute","top":"80%","margin-left":"16px"}},[_c('a',{attrs:{"href":"https://www.amerasolutions.com/","target":"_blank"}},[_c('h5',{staticStyle:{"color":"white","font-size":"0.9rem","font-weight":"lighter"}},[_vm._v(" Website: "),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.infoContact.website)+" ")])])]),_c('a',{attrs:{"href":"mailto:amera@myamera.com","target":"_blank"}},[_c('h4',{staticStyle:{"color":"white","font-size":"0.9rem","font-weight":"lighter"}},[_vm._v(" Email: "),_c('br'),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.infoContact.email)+" ")])])]),_c('a',{attrs:{"href":"tel:8552637215","target":"_blank"}},[_c('h4',{staticStyle:{"color":"white","font-size":"0.9rem","font-weight":"lighter"}},[_vm._v(" Phone number: "),_c('br'),_c('span',{staticStyle:{"font-weight":"500"}},[_vm._v(_vm._s(_vm.infoContact.phone_number))])])])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }