<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!--      <dark-Toggler class="d-none d-lg-block"/>-->
      <feather-icon icon="ArrowLeftCircleIcon" size="2x" class="mr-0 mr-sm-50" @click="returnWindows"
        style="color: #9186f2; cursor: pointer" tooltip="return"></feather-icon>
    </div>
    <div v-if="($store.getters['Users/userData'].user) ? ($store.getters['Users/userData'].user.role == 1) : ''" class="
        bookmark-wrapper
        align-items-right
        flex-grow-1
        d-none d-lg-flex
        mr-2
      ">
      <div v-if="searchResults != ''" class="dropdown" id="dropDownSearch">
        <div id="dropDownSearchItems" class="dropdown-content" style="display: block; border: none; overflow: auto;"
          autocomplete="off">
          <router-link v-for="(searchResult, index) in searchResults" :key="index" class="urlPagina" :to="{
            name: 'details-selfpaids',
            params: { client_id: searchResult.client_id },
          }">
            <b-list-group-item class="urlPagina" :ripple="false" @click="hideDropDown()">
              {{ searchResult.name }}&nbsp;{{
                searchResult.lastname
              }}
              &nbsp;({{ searchResult.email }},&nbsp;{{
                searchResult.phone_number
              }}) &nbsp;{{ lastService(searchResult.booking) }}&nbsp;{{ DateOfBirth(searchResult.birthday) }}
              &nbsp;{{ NextService(searchResult.booking) }}
            </b-list-group-item>
          </router-link>
          <!-- <a  v-for="searchResult in searchResults" :key="index" href="#about" >{{searchResult.name }}&nbsp;{{searchResult.lastname}} &nbsp;({{searchResult.email}})</a> -->
        </div>
      </div>


      <b-form-input @keyup="masterSearch('by_key_up')" type="text" v-model="searchingText" style="font-weight: bold"
        @keyup.enter="masterSearch()" />
    </div>
    <feather-icon
      v-if="($store.getters['Users/userData'].user) ? ($store.getters['Users/userData'].user.role.id == 1) : ''"
      icon="SearchIcon" @click="masterSearch()" size="2x" class="mr-1" style="color: #9186f2; cursor: pointer"
      tooltip="return"></feather-icon>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown  right
        toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-notification">
        <template #button-content v-if="($store.getters['Users/userData'].user)">
          <div class="d-sm-flex d-none user-nav" v-if="($store.getters['Users/userData'].user.role !== 3)">
            <span  class="feather-icon position-relative">
              <svg data-v-1134b199="" xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-message-square">
                <path data-v-1134b199="" d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
              </svg>
              <span  v-if="countSmsNotificationsUnreaded > 0" id="icon-sms-notication"
                class="badge badge-up badge-pill bg-danger">{{ formatCountNotifications(countSmsNotificationsUnreaded, 20)
                }}</span></span>
          </div>
        </template>

        <b-dropdown-item link-class="dropdown-menu-header dropdown">
          <span style="font-weight: bold;">SMS messages</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <template v-for="item in listSmsReceived">
          <b-dropdown-item @click="openSmsLog(item.user_number)">
            <small> <span style="font-weight:bolder">Phone: </span> {{ item.user_number }} - {{
              formatMessageNotification(item.message) }} | {{ convertDateHHMM(item.created_at) }}</small>

          </b-dropdown-item>
          <b-dropdown-divider />
        </template>

      </b-nav-item-dropdown>

      <b-nav-item-dropdown @click="updateBookingsNotifications" right
        toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-notification">
        <template #button-content v-if="($store.getters['Users/userData'].user)">
          <div class="d-sm-flex d-none user-nav" v-if="($store.getters['Users/userData'].user.role !== 3)">
            <span @click="updateBookingsNotifications" class="feather-icon position-relative">
              <svg data-v-1134b199="" xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-calendar">
                <rect data-v-1134b199="" x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line data-v-1134b199="" x1="16" y1="2" x2="16" y2="6"></line>
                <line data-v-1134b199="" x1="8" y1="2" x2="8" y2="6"></line>
                <line data-v-1134b199="" x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
              <span @click="updateBookingsNotifications" v-if="countBookingsNotificationsUnreaded > 0"
                id="icon-bookings-notication" class="badge badge-up badge-pill bg-danger">{{
                  formatCountNotifications(countBookingsNotificationsUnreaded, 20)
                }}</span></span>
          </div>
        </template>

        <b-dropdown-item link-class="dropdown-menu-header dropdown">
          <span style="font-weight: bold;">Booking notifications</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <template v-for="item in listBookinsNotification">
          <b-dropdown-item @click="openBooking(item.booking_id)">
            {{ formatMessageNotification(item.description) }}

          </b-dropdown-item>
          <b-dropdown-divider />
        </template>

      </b-nav-item-dropdown>

      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-notification">
        <template #button-content v-if="($store.getters['Users/userData'].user)">
          <div class="d-sm-flex d-none user-nav" v-if="($store.getters['Users/userData'].user.role !== 3)">
            <span class="feather-icon position-relative">
              <svg data-v-1134b199="" xmlns="http://www.w3.org/2000/svg" width="21px" height="21px" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-check-square">
                <polyline data-v-1134b199="" points="9 11 12 14 22 4"></polyline>
                <path data-v-1134b199="" d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path>
              </svg> <span v-if="listUnpreauthorizeBookings.length > 0" id="icon-preauthorization-notication"
                class="badge badge-up badge-pill bg-danger">{{
                  formatCountNotifications(listUnpreauthorizeBookings.length, 200)
                }}</span></span>
          </div>
        </template>

        <b-dropdown-item link-class="dropdown-menu-header dropdown">
          <span style="font-weight: bold;">Preauthorizations notifications</span>
        </b-dropdown-item>
        <b-dropdown-divider />

        <template v-for="item in listUnpreauthorizeBookings">
          <b-dropdown-item @click="openListPreauthorizations()">
            Confirmation #{{ item.id }}: {{ getReminderPreAuthorization(item.appoinment_datetime) }}

          </b-dropdown-item>
          <b-dropdown-divider />
        </template>

      </b-nav-item-dropdown>


      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ ($store.getters["Users/userData"].user) ? ($store.getters["Users/userData"].user.name) : '' }}
            </p>
            <span class="user-status">
              {{ ($store.getters["Users/userData"].user) ? ($store.getters["Users/userData"].user.role.role) : '' }}
            </span>
          </div>
          <b-avatar size="40px" rounded class="backgroundProfile-navbar">
            <p class="text-name-navbar">
              {{ ProfileName(($store.getters["Users/userData"].user) ? ($store.getters["Users/userData"].user.name) : '')
              }}
            </p>
          </b-avatar>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center">
          <router-link :to="{ name: 'profile' }" style="width: 100%">
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
          </router-link>
        </b-dropdown-item>

        <b-dropdown-divider />
        <b-dropdown-item link-class="d-flex align-items-center" @click="logOut">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { getMessageError } from "@core/utils/utils";
import moment from "moment";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
  BFormGroup,
  BFormInput,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import {convertToMMDDYYYYHHmm,convertToMMDDYYYY,convertToHHMM,} from "@core/utils/dates";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import { email, required } from "@core/utils/validations/validations";
import vSelect from "vue-select";
import { useWindowScroll } from "@vueuse/core";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    // Navbar Components
    DarkToggler,
    vSelect,
    BListGroup,
    BListGroupItem,
    moment,
  },
  data() {
    return {
      searchingText: "",
      searchResults: [],
      filteredSelpays: [],
      listSmsReceived: [],
      listBookinsNotification: [],
      listUnpreauthorizeBookings: [],
      indexSmsLastRead: 0,
      countSmsNotificationsUnreaded: 0,
      countBookingsNotificationsUnreaded: 0,
    };
  },
  methods: {
    convertDateHHMM(data) {
      return convertToMMDDYYYYHHmm(data);
    },
    lastService(booking) {
      let currentDateLastService = null;
      if (Object.keys(booking).length > 0) {
        let LastService = "";
        booking.forEach((element) => {
          let bookingDate = new Date(element.booking_date);
          var todayDate = new Date();

          if (element.status != 0 && bookingDate < todayDate) {
            if (bookingDate > currentDateLastService) {
              currentDateLastService = bookingDate;
            }

            let LastServiceDate =
              currentDateLastService.getMonth() +
              1 +
              "/" +
              currentDateLastService.getDate() +
              "/" +
              currentDateLastService.getFullYear().toString().substr(-2);
            LastService = "Last Service: " + LastServiceDate + " ,";
          } else {
            LastService;
          }
        });
        return LastService;
      }
    },
    NextService(booking = 0) {
      let NextService = "";
      let NextServiceDate = null;
      let currentDateNextService = null;


      if (Object.keys(booking).length > 0) {
        booking.forEach((element) => {
          if (element.status == 0) {
            var date1 = element.booking_date;
            var todayDate = new Date();
            var month = todayDate.getMonth() + 1;
            var date2 =
              todayDate.getFullYear() +
              "-" +
              month +
              "-" +
              todayDate.getDate() +
              " " +
              todayDate.getHours() +
              ":" +
              todayDate.getMinutes() +
              ":" +
              todayDate.getSeconds();

            // To calculate the time difference of two dates

            var dateBooking = new Date(date1);
            var dateToday = new Date(date2);

            var Difference_In_Time = dateBooking - dateToday;

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            //if (Difference_In_Days >= 1) {
            if (dateBooking > dateToday) {
              let b = new Date(element.booking_date);

              //Get the next service date
              if (
                currentDateNextService > b ||
                currentDateNextService == null
              ) {
                currentDateNextService = b;

              }

              NextServiceDate =
                currentDateNextService.getMonth() +
                1 +
                "/" +
                currentDateNextService.getDate() +
                "/" +
                currentDateNextService.getFullYear().toString().substr(-2);

              NextService = "Next Service: " + NextServiceDate;
            } else {
              NextService;
            }
          }
        });
        return NextService;
      }
    },
    DateOfBirth(birthday) {
      let dateOfBirth = "";
      let dateOfBirthFomate = null;
      let convertedDOB = null;

      dateOfBirthFomate = new Date(birthday);
      if (!isNaN(dateOfBirthFomate)) {
        convertedDOB =
          dateOfBirthFomate.getMonth() +
          1 +
          "/" +
          dateOfBirthFomate.getDate() +
          "/" +
          dateOfBirthFomate.getFullYear().toString().substr(-2);

        dateOfBirth = "DOB: " + convertedDOB;

        return dateOfBirth;
      } else {
        return '';
      }

    },
    masterSearch($even_type) {
      this.clearSearch();
      this.getClients($even_type);
    },
    clearSearch() {
      this.currentDateLastService = null;
      this.searchResults = [];
    },
    hideDropDown() {
      var dropDownSearchItems = document.getElementById("dropDownSearchItems");
      if (dropDownSearchItems != null) {
        dropDownSearchItems.style = "display:none";
      }
    },
    searchFieldFunction() {
      var dropDownSearchItems = document.getElementById("dropDownSearchItems");
      if (dropDownSearchItems != null) {
        dropDownSearchItems.style = "display:block";
      }
    },
    getClients(event_type) {

      if (this.searchingText.trim().length > 0) {
        if (event_type != 'by_key_up') {
          this.$swal({
            title: "Please, wait...",
            didOpen: () => {
              this.$swal.showLoading();
            },
          });
        }


        this.$http
          .get(`admin/panel/mastersearch/list?search=${this.searchingText}`)
          .then((response) => {
            this.searchResults = response.data.data.reverse();
            this.$swal.close();
          })
          .catch((res) => {
            let message = getMessageError(error);
            this.$swal({
              title: message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    async GetUnpreauthorizeBookings() {
      await this.$http
        .get(`get_unpreauthorize_bookings`)
        .then((response) => {
          this.setListUnpreauthorize(response.data.data);
        })
        .catch((error) => {
          let message = getMessageError(error);
          console.log(message);
        });

    },
    GetSmsNotificationsUnreaded() {
      let userId = this.$store.getters['Users/userData'].user.id;
      this.$http
        .get(`get_sms_notifications_unreaded/${userId}`)
        .then((response) => {
          this.countSmsNotificationsUnreaded = response.data.data.countUnreadMessage;
          let readMessages = response.data.data.readMessages;
          let unReadMessages = response.data.data.unReadMessages;
          this.loadListSms(readMessages, unReadMessages);
          this.indexSmsLastRead = response.data.data.indexSmsLastRead;
        })
        .catch((error) => {
          let message = getMessageError(error);
          console.log(message);
        });
    },
    GetBookingsNotificationsUnreaded() {
      let userId = this.$store.getters['Users/userData'].user.id;
      this.$http
        .get(`get_bookings_notifications_unreaded/${userId}`)
        .then((response) => {
          this.countBookingsNotificationsUnreaded = response.data.data.countUnreadMessage;
          let readMessages = response.data.data.readMessages;
          let unReadMessages = response.data.data.unReadMessages;
          this.loadListBookingsNotifications(readMessages, unReadMessages);
          this.indexSmsLastRead = response.data.data.indexSmsLastRead;
        })
        .catch((error) => {
          let message = getMessageError(error);
          console.log(message);
        });
    },
    loadListSms(readMessages, unreadMessages) {
      this.listSmsReceived=[];
      unreadMessages.forEach(element => this.listSmsReceived.push(element));
      //readMessages.forEach(element => this.listSmsReceived.push(element));
    },
    loadListBookingsNotifications(readMessages, unreadMessages) {
      unreadMessages.forEach(element => this.listBookinsNotification.push(element));
      readMessages.forEach(element => this.listBookinsNotification.push(element));
    },
    updateSmsNotifications() {
      let userId = this.$store.getters['Users/userData'].user.id;
      let formData = new FormData();
      formData.append("user_id", userId);

      this.$http
        .post(`update_status_sms_notification`, formData)
        .then((response) => {
          let el = document.getElementById('icon-sms-notication');
          if (el != null) {
            el.style.display = 'none';
          }
        })
        .catch((error) => {
          let message = getMessageError(error);
          console.log(message);
        });
    },
    updateBookingsNotifications() {
      let userId = this.$store.getters['Users/userData'].user.id;
      let formData = new FormData();
      formData.append("user_id", userId);

      this.$http
        .post(`update_status_booking_notification`, formData)
        .then((response) => {
          let el = document.getElementById('icon-bookings-notication');
          if (el != null) {
            el.style.display = 'none';
          }
        })
        .catch((error) => {
          let message = getMessageError(error);
          console.log(message);
        });
    },
    updatePreAuthorizationNotifications() {
      document.getElementById('icon-preauthorization-notication').style.display = 'none';
    },
    ProfileName(name) {
      return name.charAt(0).toUpperCase() + name.charAt(1).toUpperCase();
    },
    logOut() {
      this.$swal({
        title: "Please, wait...",
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.$store
        .dispatch("Users/destroyToken", "/auth/users/logout")
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Logout successfully",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.$swal({
            icon: "error",
            title: error.response,
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    returnWindows() {
      window.history.back();
    },
    setEventJavascript() {
      var body = document.getElementsByTagName("body")[0];
      let dropDownSearchItems = document.getElementById("dropDownSearchItems");

      body.addEventListener(
        "click",
        function () {
          dropDownSearchItems = document.getElementById("dropDownSearchItems");
          if (dropDownSearchItems != null) {
            dropDownSearchItems.style = "display:none";
          }
        },
        false
      );
    },
    formatMessageNotification(message) {
      let res = '';
      res = message.substring(0, 50);
      if (message.length >= 50) {
        res = res + '...';
      }
      return res;
    },
    formatCountNotifications(data, length) {
      let res = data;
      if (data > length)
        res = `+${length}`;

      return res;
    },
    openSmsLog(user_number) {
      this.$router.push({ name: 'sms_log', params: { user_number: user_number } })
    },
    openBooking(id) {
      this.$router.push({ name: 'details-reservation', params: { id: id } })
    },
    openListPreauthorizations() {
      this.$router.push({ name: 'preauthorizations' })
    },
    setListUnpreauthorize(data) {
      this.listUnpreauthorizeBookings = [];
      let res = 0;

      try {
        for (let i = 0; i < data.length; i++) {
          let date1 = moment(new Date());
          let date2 = moment(data[i].appoinment_datetime);
          res = date2.diff(date1, 'days');

          if (res < 15) {
            this.listUnpreauthorizeBookings.push(data[i]);
          }
        }

      } catch (error) {
        console.log(error)
      }



    },
    getReminderPreAuthorization(data) {
      let res = '';
      let date1 = moment(new Date());
      let date2 = moment(data);
      let diff = date2.diff(date1, 'days');
      if (diff <= 0) {
        res = "This should be pre-authorizated"
      }
      if (diff > 0) {
        res = `${diff} Days left for appoiment date`
      }
      return res;
    },
    async retrieveUserByEmailAndId() {      
      let data = {
        email: this.$store.getters['Users/userData'].user.email,
        id: this.$store.getters['Users/userData'].user.id
      }

      this.$http
        .post(`auth/users/get_amerauser_by_email_and_id`, data)
        .then((response) => {
          this.$store.getters['Users/userData'].user=response.data.data;
        })
        .catch((error) => {
          let message = getMessageError(error);
          console.log(message);
        });
    
    },
    async loadData() {
      //await this.retrieveUserByEmailAndId();
      this.setEventJavascript();
      if (this.$store.getters['Users/userData'].user) {
        if (this.$store.getters['Users/userData'].user.role !== 3) {
          try {
            this.GetSmsNotificationsUnreaded();
            this.GetBookingsNotificationsUnreaded();
            this.GetUnpreauthorizeBookings();
          } catch (error) {

          }

        }
      }
    },
    convertDateHHMM(data) {
      return convertToMMDDYYYYHHmm(data);
    },
  },
  mounted() {
    this.loadData();

    this.$root.$on('EventGetSmsList', (data) => {
      this.GetSmsNotificationsUnreaded();
        });
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => { },
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.text-name-navbar {
  font-size: 1.1rem;
  margin-bottom: 0 !important;
}

.backgroundProfile-navbar {
  background-color: $primary;
  border-radius: 100% !important;
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #3e8e41;
}

#myInput {
  box-sizing: border-box;

  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#myInput:focus {
  outline: 3px solid #ddd;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  margin-top: 41px;
  width: 732px;
}

.dropdown-content a div {
  color: black;
  padding: 10px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a div:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.navbar-nav .dropdown-menu {
  overflow: auto;
  height: 250px;
}
</style>
