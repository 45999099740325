import {
    ROL_SUPER_ADMIN,
    ROL_ADMIN,
    ROL_CORPORATE_ACCOUNT,
    ROL_DISPATCH,
    ROL_CARE_COORDINATOR,
    ROL_PROVIDER_RELATIONS,
    ROL_HUMAN_RESOURCE,
    ROL_BILLING
} from '@/@core/utils/rolesNames.js';

export default [
    //Booking center'
    {
        title: 'Booking center',
        route: 'home-corporate-acount',
        icon: 'HomeIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: null,
        },
        children: [{
                title: 'Dispatch',
                route: 'reservations',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'dispatch-board',
                },
            },
            {
                title: 'Book a Ride',
                route: 'book-a-ride',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'book-a-ride',
                },
            },
            {
                title: 'Assign Driver',
                route: 'assign-driver',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'assign-driver-provider-cna-cna-bt',
                },
            },
            {
                title: 'Pre-authorize',
                route: 'preauthorizations',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'billing-reporting',
                },
            },
        ],
    },
    //Amera Users
    {
        title: 'Amera Users',
        route: 'assign-role',
        icon: 'UserCheckIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'null',
        },
        children: [{
                title: 'Users',
                route: 'assign-role',
                icon: 'UserCheckIcon',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-the-list-of-users||onboarding-new-employees',
                },
            },

        ],
    },
    //Providers
    {
        title: 'Providers',
        route: 'provider',
        icon: 'CheckSquareIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'see-lits-providers',
        },
    },
    //Drivers
    {
        title: 'Drivers',
        route: 'second-page',
        icon: 'UserIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'see-the-list-of-cna||list-rates-drivers',
        },
        children: [{
                title: 'Drivers CNA',
                route: 'cna',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-the-list-of-cna||assign-driver-provider-cna-cna-bt||onboarding-new-drivers',
                },
            },
            {
                title: 'CNA BT',
                route: 'bt',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-the-list-of-cna-bt||assign-driver-provider-cna-cna-bt||onboarding-new-drivers',
                },
            },
            {
                title: 'Driver Provider',
                route: 'providers',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-the-list-of-providers||assign-driver-provider-cna-cna-bt||onboarding-new-drivers',
                },
            },
            {
                title: 'Driver Documents',
                route: 'driverDocuments',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'list-driver-documents||onboarding-new-drivers'
                },
            },
            {
                title: 'Driver Rating',
                route: 'list-rates-drivers',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'list-driver-documents'
                },
            },
        ],                                  
    },
    //Client
    {
        title: 'Client',
        route: 'second-page',
        icon: 'UserIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'null',
        },
        children: [{
                title: 'Corporate accounts',
                route: 'clients',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-the-list-of-corporates',
                },
            },
            {
                title: 'Contract accounts',
                route: 'contracts',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-the-list-of-corporates',
                },
            },
            {
                title: 'SelfPays',
                route: 'all-selfpaids',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-the-list-of-selfpays',
                },
            },
            {
                title: 'Patients',
                route: 'all-patient-admin-site',
                meta: {
                    requiresAuth: true,
                    role: ROL_SUPER_ADMIN,
                    permission: 'see-patient-list',
                },
            },
        ],
    },
    //Reports
    {
        title: 'Reports',
        icon: 'CalendarIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'see-billing-list',
        },
        children: [{
            title: 'Billing Export',
            route: 'billing-export',
            meta: {
                requiresAuth: true,
                role: null,
                permission: 'billing-reporting',
            },
        }, ],
    },
    //Support
    {
        title: 'Support',
        route: 'second-page',
        icon: 'SettingsIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'see-support-page',
        },
        children: [{
            title: 'Contact Information',
            route: 'information-contact',
            meta: {
                requiresAuth: true,
                role: null,
                permission: 'see-support-page',
            },
        }, ],
    },
    //Billing
    {
        title: 'Billing',
        route: 'billing',
        icon: 'CreditCardIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'internal-financial-reports',
        },
    },
    {
        title: 'Drivers Remittance',
        route: 'remittance-reports',
        icon: 'CalendarIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'internal-financial-reports',
        },
    },
    //Settings
    {
        title: 'Settings',
        route: 'second-page',
        icon: 'ClipboardIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'settings',
        },
        children: [{
                title: 'Prices Selfpay',
                route: 'all-prices-selfpaids',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-prices-selfpay',
                },
            },
            {
                title: 'Prices Companys',
                route: 'all-prices-corporate-account',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-prices-ca',
                },
            },
            {
                title: 'States Cities',
                route: 'states-cities',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'see-list-states-cities',
                },
            },
            {
                title: 'Deleted companies',
                route: 'deleted-companies',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'delete-records',
                },
            },
            {
                title: 'Categories documents',
                route: 'documents-categories',
                meta: {
                    requiresAuth: true,
                    role: null,
                    permission: 'delete-records',
                },
            },
        ],

    },
    //Sms
    {
        title: 'Sms',
        route: 'sms_log',
        icon: 'CheckSquareIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'see-sms-page',
        },
    },
    //Profile
    {
        title: 'Profile',
        route: 'profile',
        icon: 'UserIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'change-profile',
        },
    },
    {
        title: 'Amera CRM',
        route: 'amera_crm',
        icon: 'CheckSquareIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'amera-crm',
        },
    },
    {
        title: 'Amera QB',
        route: 'amera_qb',
        icon: 'CheckSquareIcon',
        meta: {
            requiresAuth: true,
            role: null,
            permission: 'amera-qb',
        },
    },
    //Bookings ROL_CORPORATE_ACCOUNT
    {
        title: 'Bookings',
        icon: 'HomeIcon',
        route: 'bookings',
        meta: {
            requiresAuth: true,
            role: ROL_CORPORATE_ACCOUNT,
            permission: null,
        },
        children: [{
                title: 'Request a trip',
                route: 'request-service',
                meta: {
                    requiresAuth: true,
                    role: ROL_CORPORATE_ACCOUNT,
                    permission: null,
                },
            },
            {
                title: 'Bookings',
                route: 'bookings',
                meta: {
                    requiresAuth: true,
                    role: ROL_CORPORATE_ACCOUNT,
                    permission: null,
                },
            },
        ],
    },
    //Patients ROL_CORPORATE_ACCOUNT
    {
        title: 'Patients',
        icon: 'MapPinIcon',
        route: 'all-patient',
        meta: {
            requiresAuth: true,
            role: ROL_CORPORATE_ACCOUNT,
            permission: null,
        },
        children: [{
                title: 'Create a patient',
                route: 'create-patient',
                meta: {
                    requiresAuth: true,
                    role: ROL_CORPORATE_ACCOUNT,
                    permission: null,
                },
            },
            {
                title: 'Patients',
                route: 'all-patient',
                meta: {
                    requiresAuth: true,
                    role: ROL_CORPORATE_ACCOUNT,
                    permission: null,
                },
            },
        ],
    },
    //Billing ROL_CORPORATE_ACCOUNT
    {
        title: 'Billing',
        route: 'billing',
        icon: 'DollarSignIcon',
        meta: {
            requiresAuth: true,
            role: ROL_CORPORATE_ACCOUNT,
            permission: null,
        },
    },
    //Profile ROL_CORPORATE_ACCOUNT
    {
        title: 'Profile',
        route: 'profile',
        icon: 'UserIcon',
        meta: {
            requiresAuth: true,
            role: ROL_CORPORATE_ACCOUNT,
            permission: null,
        },
    },

]